import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './reroll.scss';
import '../../generic-page.scss';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const RevGuidesRerollPage: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-reroll'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Reroll</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_reroll.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Reverse: 1999 Reroll guide</h1>
          <h2>
            Our reroll guide for Reverse: 1999 that will help you pick the best
            character to start strong in the game!
          </h2>
          <p>
            Last updated: <strong>03/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Should you reroll?" />
        <p>
          Rerolling is not a must, as the game is fairly generous, and you can
          clear all content with whomever you want.
        </p>
        <p>
          However, if you’re looking for a head start or for a character in
          specific, rerolling is a very worthwhile option, that can take between
          7 and 10 minutes.
        </p>
        <SectionHeader title="How to reroll" />
        <p>
          The first thing you will need to prepare if you plan to reroll is your
          phone or an emulator. While the game has a PC client available and the
          Guest Account feature is there, the only way to reset it is{' '}
          <strong>by reinstalling the whole game.</strong> By comparison, when
          using an emulator, you can abuse the master instance trick to quickly
          restart the process.
        </p>
        <h5>Reroll on your phone</h5>
        <p>
          While Reverse:1999 has a Guest Account system, it takes 15 days to
          delete it (via Support) and what's worse,{' '}
          <strong className="red">
            Clearing Cache doesn't remove the Guest Account.
          </strong>{' '}
          We also tried reinstalling the game, rooting the device and none of
          the less-intrusive methods seem to work. This means that the only
          option for you to reroll on mobile is using the{' '}
          <strong>Salt Method.</strong>
        </p>
        <p>
          The Salt Method abuses a mechanic available in Google Gmail. Here's
          how it works:
        </p>
        <ul>
          <li>
            To use a salted email, you add a +anything between the name and the
            @gmail portion of your e-mail address when you create an account.
            For example, if your gmail account name is{' '}
            <strong>myname@gmail.com</strong>, a salted e-mail would be{' '}
            <strong>myname+anything@gmail.com</strong>.
          </li>
          <li>
            The string behind the + symbol can be any letters or numbers of any
            length. Still, to easily keep track of rerolled accounts,{' '}
            <strong>we recommend using numbers</strong> - so the email you will
            use to register will look like this myname+1@gmail.com. Any e-mails
            sent to a salted e-mail will be redirected to the e-mail without the
            salt used (and you will receive a code to input in the client to
            confirm account creation).
          </li>
        </ul>
        <p>
          If you're super serious about rerolling, we suggest to create a sheet
          or a txt file where you will track what salted account got what pulls.
        </p>
        <p>
          Once you're done with rolling, you can simply bind the account using
          Google or Facebook to avoid using the salt email to login.
        </p>
        <p>
          Also, for iOS users - we're not sure if reinstalling the game will
          remove the guest account, but most likely it won't. So you will have
          to reroll on an Android device, PC or emulator, bind the account and
          then play on your default device.
        </p>
        <h5>Reroll on an emulator</h5>
        <p>
          This option is better for those who have a decent PC and can handle
          multiple instances at once - or just can reroll using a single
          instance. Since the game is turn-based and reaching the place where
          you can reroll takes roughly 10 minutes, it's easy to synchronize your
          instances and not lose your mind while you're doing it.
        </p>
        <p>
          We suggest using{' '}
          <strong>
            BlueStacks for playing Reverse:1999 as the game works very well on
            it
          </strong>{' '}
          and allows you to easily reroll to start strong. Also, if you download
          BlueStacks from our link, you will support the website!
        </p>
        <div className="banner reroll">
          <div className="cta">
            <h4>Reroll Reverse:1999 on PC</h4>
            <OutboundLink href="https://bstk.me/rOwY3uFj2" target="_blank">
              <Button variant="primary">Reroll now on BlueStacks</Button>
            </OutboundLink>
          </div>
        </div>
        <SectionHeader title="Reroll Method" />
        <Row xs={1} xl={2} xxl={2} className="reroll-paths">
          <Col>
            <div className="box">
              <div className="option hard"></div>
              <div className="inside">
                <p className="time">
                  Time needed: <strong>7/10 minutes per reroll</strong>
                </p>
                <p>
                  In this reroll, you will aim to pull one or more 6✦ from the
                  current event banner, rate up banner or, if it’s available,
                  the banner that allows you to choose who you want as the rate
                  up.
                </p>
                <p>
                  Currently, the Beginner Banner “The First Drop of Rain” is not
                  worth pulling on anymore, as it doesn’t share pity with other
                  banners and the three 6✦ characters featured there (Lilya,
                  Eternity, Regulus) have been outclassed by newer units. More
                  on this in the “Who to aim for” section below.
                </p>
                <hr />
                <h6>Process</h6>
                <ol>
                  <li>
                    {' '}
                    Whenever possible, skip the story in Tutorial (a Skip button
                    will appear in top right corner). This will save you whole
                    20 minutes (still, we suggest to play the tutorial at least
                    once for the story and to learn more about the game),
                  </li>
                  <li>
                    Clear 1-4 and grab the pre-register rewards from Mail
                    (enough for 11 pulls).
                  </li>
                  <li>
                    Pull in one of the featured banners. The goal here is to get
                    either the featured character or lose the 50/50 for one of
                    the staple units, which will give you a guaranteed for the
                    next rate up you wish to pull for.
                  </li>
                  <li>
                    If you got who you want, you're done, congrats! If not, it's
                    time to create a new instance and try again.
                  </li>
                </ol>
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
        <SectionHeader title="Who to aim for:" />
        <p>
          Most events feature characters who are either meta defining or have
          the current and future patches favoring them. This guarantees a strong
          head start for a new account, especially since you can power level
          these new units by using the event shop. If you are unsure about the
          current banner, check out our “Should you Pull?” guides to see if that
          character is the right choice for you. Checking the Tier List is also
          good since our Tier List is updated regularly based on the CN version,
          so you can feel confident that your investment will last several
          months.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Tier list"
            link="/re1999/tier-list"
            image={
              <StaticImage
                src="../../../images/reverse/categories/category_tier.png"
                alt="Tier list"
              />
            }
          />
        </Row>
        <p>
          Another option is to pull on a rate up banner for a past character, or
          stick with a character from the standard pool who spooked you in the
          event banner. There are numerous characters on the standard roster who
          stood the test of time and are excellent investments to guarantee a
          smooth start to your account.
        </p>
        <div className="reroll-char-info reroll first">
          <div className="top star">
            <p className="name">Tooth Fairy</p>
            <p>Healer, Purify, Crit. Supp</p>
          </div>
          <div className="middle">
            <div className="character">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="tooth-fairy"
                  mode="card"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="pros-cons">
              <p>Pros & Cons:</p>
              <ul className="pros">
                <li>Consistent healing;</li>
                <li>
                  Stuck as one of the best supports for critical teams for a
                  year;
                </li>
                <li>Can purify debuffs with her ultimate;</li>
              </ul>
              <ul className="cons">
                <li>Very Squishy;</li>
                <li>
                  You may prefer other options if your team isn’t centered
                  around landing criticals.
                </li>
              </ul>
            </div>
            <div className="ratings">
              <p>Ratings:</p>
              <div className="ratings-box first s-plus">
                <h6>S+</h6>
                <p>Overall</p>
              </div>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top intellect">
            <p className="name">6</p>
            <p>Buffer, Debuffer, Purify</p>
          </div>
          <div className="middle">
            <div className="character">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="6"
                  mode="card"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="pros-cons">
              <p>Pros & Cons:</p>
              <ul className="pros">
                <li>Can fit in pretty much any team;</li>
                <li>
                  Has it all: Buffs, debuffs, damage and even Purify on demand;
                </li>
                <li>Can grant “Empower Incantation” to an ally;</li>
              </ul>
              <ul className="cons">
                <li>Expensive to raise, needs Insight 3 to shine;</li>
                <li>Dedicated supports become better choices down the road.</li>
              </ul>
            </div>
            <div className="ratings">
              <p>Ratings:</p>
              <div className="ratings-box first s">
                <h6>S</h6>
                <p>Overall</p>
              </div>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top plant">
            <p className="name">An-an Lee</p>
            <p>Buffer</p>
          </div>
          <div className="middle">
            <div className="character">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="an-an-lee"
                  mode="card"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="pros-cons">
              <p>Pros & Cons:</p>
              <ul className="pros">
                <li>Has survivability, damage, and good buffs,</li>
                <li>AP Generous due to upgrading her own cards,</li>
                <li>Extremely versatile, fits most team comps,</li>
                <li>Synergy with AP Greedy carries.</li>
              </ul>
              <ul className="cons">
                <li>
                  Needs to play around her switching modes [Break Time] and
                  [Exorcism Time],
                </li>
                <li>Jack of all trades, master of none.</li>
              </ul>
            </div>
            <div className="ratings">
              <p>Ratings:</p>
              <div className="ratings-box first a-plus">
                <h6>A+</h6>
                <p>Overall</p>
              </div>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top beast">
            <p className="name">Melania</p>
            <p>Damage Dealer</p>
          </div>
          <div className="middle">
            <div className="character">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="melania"
                  mode="card"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="pros-cons">
              <p>Pros & Cons:</p>
              <ul className="pros">
                <li>
                  {' '}
                  Fits well in any game mode, both against single-target or
                  waves of enemies;
                </li>
                <li> Self-reliant kit, with some sustain and high burst;</li>
                <li>
                  {' '}
                  Able to shut down enemy ultimates by stealing Moxie, making
                  content easier;
                </li>
              </ul>
              <ul className="cons">
                <li>
                  {' '}
                  No longer the top carry in the game, now that Reality carries
                  have better Support options;
                </li>
                <li>
                  {' '}
                  Can be AP greedy if you wish to stack her passive fast.
                </li>
              </ul>
            </div>
            <div className="ratings">
              <p>Ratings:</p>
              <div className="ratings-box first a-plus">
                <h6>A+</h6>
                <p>Overall</p>
              </div>
            </div>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
        <SectionHeader title="The Gacha" />
        <StaticImage
          src="../../../images/reverse/generic/reroll_3.webp"
          alt="Reroll guide"
        />
        <p>
          Below you can find the gacha rules, rates, and characters available on
          the standard pool.
        </p>
        <h5>Summoning Rates</h5>
        <ul>
          <li>
            Basic rate of summoning 6 star characters: <strong>1.5%</strong>
          </li>
          <li>
            Basic rate of summoning 5 star characters: <strong>8.5%</strong>
          </li>
          <li>
            Basic rate of summoning 4 star characters: <strong>40%</strong>
          </li>
          <li>
            Basic rate of summoning 3 star characters: <strong>45%</strong>
          </li>
          <li>
            Basic rate of summoning 2 star characters: <strong>5%</strong>.
          </li>
        </ul>
        <p>
          Every 10 summons guarantees at least 1 4-star or better character.
          Under this rule, the overall chance of drawing a 6✦ is 2,36%. If you
          have not obtained any 6✦ character after 60 summons, the rate of
          summoning a 6✦ character in the next summon will rise from 1.5% to 4%.
          After that, every summon that doesn’t grant a 6✦ character will
          increase the rate by <strong>2.5%</strong>.
        </p>
        <ul>
          <li>Every 70 summons guarantees 1 6✦ character.</li>
          <li>
            After you have obtained a 6✦ character, the rate will be reset to
            1.5%.
          </li>
          <li>
            On limited and rate up banners, there’s a 50% chance you will get
            the featured character. If you lose the first 50/50, then the next
            6✦ is guaranteed to be the featured character.
          </li>
          <li>
            Pity is shared between Limited and Rate Up banners, and it carries
            over as well.
          </li>
        </ul>
        <h5>Duplicate Characters: </h5>
        <ul>
          <li>
            When you obtain the same <strong>6-star</strong> character for the
            2nd-6th time, the duplicate character will be converted into 1
            Artifice of the character and 12 Albums of the Lost every time;
            starting from the 7th time, the duplicate character will be
            converted into 28 Albums of the Lost every time.
          </li>
          <li>
            When you obtain the same <strong>5-star</strong> character for the
            2nd-6th time, the duplicate character will be converted into 1
            Artifice of the character and 3 Albums of the Lost every time;
            starting from the 7th time, the duplicate character will be
            converted into 7 Albums of the Lost every time.
          </li>
          <li>
            When you obtain the same <strong>4-star</strong> character for the
            2nd-6th time, the duplicate character will be converted into 1
            Artifice of the character and 8 Tracks of the Lost every time;
            starting from the 7th time, the duplicate character will be
            converted into 12 Tracks of the Lost every time.
          </li>
          <li>
            When you obtain the same <strong>3-star</strong> character for the
            2nd-6th time, the duplicate character will be converted into 1
            Artifice of the character and 4 Tracks of the Lost every time;
            starting from the 7th time, the duplicate character will be
            converted into 7 Tracks of the Lost every time.
          </li>
          <li>
            When you obtain the same <strong>2-star</strong> character for the
            2nd-6th time, the duplicate character will be converted into 1
            Artifice of the character and 3 Tracks of the Lost every time;
            starting from the 7th time, the duplicate character will be
            converted into 5 Tracks of the Lost every time.
          </li>
        </ul>
        <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
      </div>
    </DashboardLayout>
  );
};

export default RevGuidesRerollPage;

export const Head: React.FC = () => (
  <Seo
    title="Reroll | Reverse: 1999 | Prydwen Institute"
    description="Our reroll guide for Reverse: 1999 that will help you pick the best character to start strong in the game!"
    game="reverse"
  />
);
